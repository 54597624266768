import React, {useContext, useState, useEffect} from "react"

import {createTheme, ThemeProvider} from "@mui/material/styles"
import Layout from "../layouts"

import {GlobalStateContext} from "../context/GlobalContextProvider"
import MUIDataTable from "mui-datatables"
import {styled} from "@mui/material/styles"
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip"
import Paper from "@mui/material/Paper"
import {
    Typography,
    TextField,
    Card,
    CardHeader,
    CardContent,
    Box,
    Button,
    IconButton,
} from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import AutorenewIcon from "@mui/icons-material/Autorenew"
import QrCodeIcon from "@mui/icons-material/QrCode"
import SmartphoneIcon from "@mui/icons-material/Smartphone"
import InfoIcon from "@mui/icons-material/Info"
import {gUtils} from "../components/utils/gutils"
import {cfputils} from "../components/utils/cfputils";

export default function Developer({location}) {

    const {gCfp, gDev, gCompanyDevice,gStripe, gLoginEmployee,ts} =
        useContext(GlobalStateContext)
    const [sesameKey, setKey] = useState({UUID: "", secretKey: ""})
    const [apiResponse, setApiResponse] = useState({})

    const CustomWidthTooltip = styled(({className, ...props}) => (
        <Tooltip {...props} classes={{popper: className}}/>
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
        },
    })
    useEffect(()=>{
        if (gStripe.customer.id){
          reset()
        }

    },[gStripe.customer.id])
    // MUIDataTable 客製化
    const customTheme = createTheme({
        components: {
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        display: "none",
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        border: "none",
                        "&:first-of-type": {
                            borderTopLeftRadius: "6px",
                            borderBottomLeftRadius: "6px",
                        },
                        "&:last-of-type": {
                            borderTopRightRadius: "6px",
                            borderBottomRightRadius: "6px",
                        },
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&:nth-of-type(odd)": {
                            backgroundColor: "#f8f8f8",
                        },
                        "&:hover": {
                            cursor: "pointer",
                            textDecoration: "underline",
                            "& td": {
                                textDecoration: "underline",
                            },
                        },
                    },
                },
            },
            MUIDataTableBodyRow: {
                styleOverrides: {
                    root: {
                        border: "none"
                    },
                },
            }
        },
    })


    useEffect(() => {
        setApiResponse(gDev.apiResponse)
    }, [gDev.apiResponse])
    const  reset=()=>{
        setApiResponse({})
    }

    const infoItem = {
        display: "flex",
        alignItems: "center",
    }

    const readQrcode = (imgUrl) => {
       // console.log("当前key", imgUrl)

        cfputils.readQrcode(imgUrl, (e, d) => {
            if (d) {
                if (d.keyLevel!==0){
                    ts({open: true, msg: '登録失敗、デバイスの"オーナー"権限が必要です'})
                    return
                } else if (gUtils.isTouchModel(d.deviceModel)) {
                    ts({open: true, msg: "このモデルはサポートされていません。"})
                    return
                } else {
                    console.log("当前key----", d)
                    setKey(d)
                }


            }

        })

    }

    const paperStyles = {
        minHeight: "3em", // 最小高さ
        overflow: "auto", // 文字數超過minHeigh的話顯示scrollbar
        padding: "8px",
        borderRadius: "6px",
        wordBreak: "break-all",
        whiteSpace: 'pre-wrap', // 保留空白符和换行符
        overflowX: 'auto' // 如果内容过宽，添加滚动条
    }

    const columns = [
        {
            name: "バージョン",
            options: {
                setCellHeaderProps: () => ({
                    style: {opacity: "0.6"},
                }),
                customBodyRenderLite: (dataIndex) => {
                    let value = updatedDownloadData[dataIndex].fileName
                    return (
                        <Typography
                            href="#"
                            sx={{
                                color: "#000000",
                            }}
                            onClick={(e) => {
                                e.preventDefault()
                                gCfp.downloadFile(`zipfile/${value}`, (e, r) => {
                                    if (r) {
                                        window.location.href = r
                                    }
                                })
                            }}
                        >
                            {updatedDownloadData[dataIndex].v}
                        </Typography>
                    )
                },
            },
        },
        {
            name: "デバイス名",
            options: {
                setCellHeaderProps: () => ({
                    style: {opacity: "0.6"},
                }),
                customBodyRenderLite: (dataIndex) => {
                    return updatedDownloadData[dataIndex].device
                },
            },
        },
    ]

    const downloadData = [
        { device: "SESAME 5", fileName: "sesame5_30_5_f826b5.zip" },
        { device: "SESAME 5 Pro", fileName: "sesame5pro_30_7_f826b5.zip" },
        { device: "SESAME 5 北米北欧版", fileName: "sesame5us_30_16_f826b5.zip" },
        { device: "SESAME Bot 2", fileName: "sesamebot2_30_17_f826b5.zip" },
        // { device: "Hub 3", fileName: "hub3_30_13_4dda57.zip" },
        { device: "CANDY HOUSE Remote", fileName: "remote_30_14_9f3128.zip" },
        { device: "CANDY HOUSE Remote nano", fileName: "remoten_30_15_9f3128.zip" },
        { device: "SESAME Touch", fileName: "sesametouch1_30_10_c1a0c3.zip" },
        { device: "SESAME Touch Pro", fileName: "sesametouch1pro_30_9_c1a0c3.zip" },
        { device: "Open Sensor", fileName: "opensensor1_30_8_9f3128.zip" },
        { device: "SESAME Bike 2", fileName: "sesamebike2_30_6_f826b5.zip" },
        { device: "SESAME Bot 1", fileName: "sesamebot1_21_2_369eb9.zip" },
        { device: "SESAME 4", fileName: "sesame_421_4_50ce5b.zip" },
        { device: "SESAME 3", fileName: "sesame_221_0_8c080c.zip" },
        { device: "SESAME Bike 1", fileName: "sesamebike1_21_3_d7162a.zip" },
    ]
    const updatedDownloadData = downloadData.map(item => ({
        ...item,
        v: item.fileName.replace('.zip', '')
    }))

    return (
        <Layout location={location}>
            {gLoginEmployee.loginInfo.tag && (
                <Card>
                    <CardHeader
                        title={
                            <Typography variant="h2" sx={{ml: "9px"}}>
                                開発者向け
                            </Typography>
                        }
                    />
                    <CardContent
                        sx={{
                            mt: 2,
                            p: "0px",
                        }}
                    >
                        <Box sx={{display: "flex", flexDirection: "row"}}>
                            <Card>
                                <CardHeader
                                    title={<Typography variant="h3">APIキー</Typography>}
                                />
                                <CardContent>
                                    <Box className="currentPlan" sx={{...infoItem}}>
                                        <Typography>{gDev.apiKey.apiKeyValue||''}</Typography>
                                        <Tooltip title="コピー">
                                            <IconButton
                                                size="small"
                                                sx={{marginLeft: "6px"}}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(gDev.apiKey.apiKeyValue||'')
                                                }}
                                            >
                                                <ContentCopyIcon fontSize="inherit"/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="APIキーの変更">
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    gDev.changeKey()
                                                }}
                                            >
                                                <AutorenewIcon fontSize="inherit"/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader
                                    title={
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                // color: "#8a8994",
                                                display: "inline-block",
                                            }}
                                        >
                                            今月のAPIご利用回数
                                        </Typography>
                                    }
                                />
                                <CardContent>
                                    <Box sx={{...infoItem}}>
                                        <Typography>{`${gDev.useCount}回`}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                        <Card>
                            <CardHeader title={<Typography variant="h3">デモ</Typography>}/>
                            <CardContent>
                                <Box sx={{...infoItem, paddingTop: "6px"}}>
                                    <Typography variant="h3" sx={{fontWeight: "normal"}}>
                                        1.デバイスを選択
                                    </Typography>
                                    <CustomWidthTooltip
                                        title={
                                            <div>
                                                【QRコード】QRコードからデバイスをインポート
                                                <br/>
                                                【個人で登録済のデバイス】個人でセサミアプリに登録済みのデバイスをインポート
                                            </div>
                                        }
                                        placement="right-start"
                                    >
                                        <InfoIcon
                                            sx={{color: "#cccccc", cursor: "pointer", ml: "5px"}}
                                        />
                                    </CustomWidthTooltip>
                                </Box>
                                <Box sx={{display: "flex"}}>
                                    <Button
                                        varient="text"
                                        component="label"
                                        startIcon={<QrCodeIcon/>}
                                    >
                                        QRコード
                                        <input
                                            type="file"
                                            hidden
                                            onChange={(event) => {
                                                if (event.target.files[0]) {

                                                    readQrcode(event.target.files[0])
                                                }
                                            }}
                                        />
                                    </Button>
                                    <Button
                                        variant="text"
                                        startIcon={<SmartphoneIcon/>}
                                        onClick={() => {
                                            gCompanyDevice.getUserDevices()
                                        }}
                                    >
                                        個人で登録済のデバイス
                                    </Button>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "6px",
                                        maxWidth: "100%",
                                    }}
                                >
                                    {gCompanyDevice.userDevices
                                        .filter(
                                            (i) =>
                                                i.deviceModel !== "wm_2" &&
                                                i.deviceModel !== "hub_3" &&
                                                i.deviceModel !== "ssm_touch_pro" &&
                                                i.deviceModel !== "ssm_touch" &&
                                                i.deviceModel !== "remote" &&
                                                i.deviceModel !== "remote_nano" &&
                                                i.deviceModel !== "open_sensor_1"
                                        )
                                        .map((i) => {
                                            return (
                                                <Button
                                                    size="small"
                                                    sx={{
                                                        color: "#ffffff",
                                                        backgroundColor: "rgb(40,174,177,0.8)",
                                                        '&:hover': {
                                                            backgroundColor: "rgb(40,174,177,0.4)",
                                                        },
                                                        borderRadius: "6px",
                                                        paddingRight: "16px",
                                                        paddingLeft: "16px",
                                                        marginLeft: "6px"

                                                    }}
                                                    key={i.deviceUUID}
                                                    onClick={() => {
                                                        setApiResponse({})
                                                        setKey(i)
                                                        // setKey({
                                                        //   UUID: i.deviceUUID,
                                                        //   secretKey: i.secretKey,
                                                        // })
                                                    }}
                                                >
                                                    {i.deviceName}
                                                </Button>
                                            )
                                        })}
                                </Box>
                            </CardContent>
                            <CardContent sx={{paddingBottom: "unset"}}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        label="デバイスUUID"
                                        variant="outlined"
                                        sx={{marginBottom: "10px", flexGrow: "1"}}
                                        fullWidth
                                        value={sesameKey.deviceUUID || ""}
                                        onChange={(e) => {
                                            setKey({...sesameKey, deviceUUID: e.target.value})
                                        }}
                                    />

                                    <TextField
                                        size="small"
                                        label="デバイスシークレットキー"
                                        variant="outlined"
                                        fullWidth
                                        value={sesameKey.secretKey || ""}
                                        onChange={(e) => {
                                            setKey({...sesameKey, secretKey: e.target.value})
                                        }}
                                        sx={{
                                            marginBottom: "10px",
                                            marginLeft: "10px",
                                            flexGrow: "1",
                                        }}
                                    />
                                </Box>
                            </CardContent>

                            {sesameKey.deviceUUID && sesameKey.secretKey && (
                                <>
                                    <CardContent>
                                        <Typography
                                            variant="h3"
                                            sx={{fontWeight: "normal", paddingTop: "8px"}}
                                        >
                                            2.デモを実行する
                                        </Typography>

                                        <Box sx={{display: "flex", marginTop: "6px"}}>
                                            <Button
                                                sx={{marginRight: "10px", color: "#28aeb1"}}
                                                disabled={!sesameKey.deviceUUID}
                                                variant="outlined"
                                                onClick={() => {
                                                    console.log("result", sesameKey)
                                                    gDev.getState(sesameKey.deviceUUID)
                                                }}
                                            >
                                                ステータス取得
                                            </Button>
                                            <Button
                                                sx={{marginRight: "10px", color: "#28aeb1"}}
                                                disabled={!sesameKey.deviceUUID}
                                                variant="outlined"
                                                onClick={() => {
                                                    gDev.getHistory(sesameKey.deviceUUID)
                                                }}
                                            >
                                                履歴取得
                                            </Button>
                                            <Button
                                                sx={{marginRight: "10px", color: "#28aeb1"}}
                                                disabled={!sesameKey.deviceUUID || !sesameKey.secretKey}
                                                variant="outlined"
                                                onClick={() => {
                                                    let   cmd=sesameKey.deviceModel==='bot_2'?89:88

                                                console.log("dasdadasdasdasd",cmd)
                                                    gDev.cmdLock(
                                                        cmd,
                                                        sesameKey.deviceUUID,
                                                        sesameKey.secretKey
                                                    )
                                                }}
                                            >
                                                デバイス操作
                                            </Button>
                                        </Box>
                                    </CardContent>
                                    <CardContent>
                                        <Paper style={paperStyles} variant="outlined">
                                            {JSON.stringify(apiResponse,null,2)}
                                        </Paper>
                                    </CardContent>
                                </>
                            )}
                        </Card>
                        <Card>
                            <CardHeader
                                title={
                                    <Typography variant="h3" sx={{marginBottom: "10px"}}>
                                        最新ファームウェア ダウンロード
                                    </Typography>
                                }
                            />

                            <CardContent>
                                <ThemeProvider theme={customTheme}>
                                    <MUIDataTable
                                        data={updatedDownloadData}
                                        columns={columns}
                                        options={{
                                            setTableProps: () => {
                                                return {
                                                    fullWidth: true,
                                                    size: "small",
                                                }
                                            },
                                            sort: false,
                                            filter: false,
                                            search: false,
                                            print: false,
                                            download: false,
                                            viewColumns: false,
                                            customToolbar: null,
                                            pagination: false,
                                            rowHover: false,
                                            selectableRows: "none",
                                            elevation: 0,
                                        }}
                                    />
                                </ThemeProvider>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            )}
        </Layout>
    )
}
